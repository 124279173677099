<template>
  <div class="loanOffer">
    <component :is="template"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwLoanOffer',

  components: {
    'cw-car-loan-offer': () => import('@fi/pages/steps/CarLoanApplicationLoanOffer'),
    'cw-instalment-and-continuous-loan-offer': () => import('@fi/pages/steps/InstalmentAndContinuousLoanOffer'),
    'cw-brokered-loan-offer': () => import('@fi/pages/steps/brokering/BrokeredLoanOffer'),
  },

  data: () => ({
    template: '',
  }),

  computed: {
    ...mapGetters({
      loanType: 'application/getLoanType',
      workflowType: 'application/getWorkflowType',
    }),
  },

  created() {
    const type = this.loanType;

    const offerTypes = {
      continuousLoan: 'cw-instalment-and-continuous-loan-offer',
      instalmentLoan: 'cw-instalment-and-continuous-loan-offer',
      carInstalmentLoan: 'cw-car-loan-offer',
    };

    this.template = this.workflowType === 'broker' ? 'cw-brokered-loan-offer' : offerTypes[type];
  },
};
</script>
